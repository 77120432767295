import { app } from "@/plugins/appAxios";
import { mangeApi } from "@/plugins/mangeAxios";
import { api } from "@/plugins/axios";

import Vue from "vue";
export function getObsTempToken(data) {
  return Vue.axios.get(
    "http://resources.suzi.com.cn/gnsl_obs/temp/token",
    data
  );
}

export function upload(data) {
  return Vue.axios.post("http://bc-manage-api.suzi.com.cn/upload", data);
}

// 网页支付
export function payUnified(data) {
  return app("/pay/unified", data);
}

// 网页支付
export function getPlatformRatio(data) {
  return api("/generate/platform_comm/info", data);
}

// 快捷登录
export function quickLogin(data) {
  return app("/api_quick_login", data);
}

// 获取物流单列表
export function getDriverExpressList(data) {
  return app("/express_order/driver_express_list", data);
}

// 揽货物流单
export function confirmExpress(data) {
  return app("/express_order/order_express_confirm", data);
}

// 送达物流单
export function confirmProxyDelivery(data) {
  return app("/express_order/shopping_order_buyer_express_confirm", data);
}

// 送达物流单
export function confirmPublicDelivery(data) {
  return app("/express_order/business_buyer_express_confirm", data);
}

// 送达物流单
export function confirmPrivateDelivery(data) {
  return app("/express_order/buyer_express_confirm", data);
}

// 获取物流单
export function getDriverExpressPopupList(data) {
  return app("/express_order/driver_operation_list", data);
}

// 获取物流单
export function createSettleOrder(data) {
  return mangeApi("/check_account/create_settle_order", data);
}

export function createPaymentOrder(data) {
  return app("/order_payment/create_order_payment", data);
}

export function createExpressBalance(data) {
  return app("/express_order/create_express_balance", data);
}

// 物流单详情
export function expressOrderDetail(data) {
  return app("/express_order/detail_msg", data);
}

// 商品详情
export function getProductDetail(data) {
  return app("/select_product/product_detail_h5", data);
}

export function orgBaseDetail(data) {
  return app("/sys_org/base_org_info", data);
}

export function getProduct(data) {
  return app("/business_order/get_product/app", data);
}

export function getPriceCount(data) {
  return mangeApi("/business_order/calculation_price", data);
}

export function getAllAddress(data) {
  return mangeApi("/area/all_area", data);
}

export function moblieLogin(data) {
  return app("/wx/moblie_login", data);
}

export function receiveAddress(data) {
  return app("/user_center/receive_address/add/info/", data);
}

export function createBusinessOrder(data) {
  return app("/business_order/create_order", data);
}

export function orgDetail(data) {
  return app("/sys_org/org_detail", data);
}

export function getProductList(data) {
  return app("/select_product/product", data);
}

export function getGenerateOrderList(data) {
  return mangeApi("/generate/generate_order/buyer_list", data);
}
export function getOrgCouponDetail(data) {
  return app("/profit_coupon/org_coupon_detail", data);
}

export function receiveOrgCoupon(data) {
  return app("/profit_coupon/receive_org_coupon", data);
}

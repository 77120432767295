import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import './plugins/vant.js'
import './plugins/element-ui.js'
import './plugins/utils.js'
import '@/assets/styles/reset.css'
import '@/plugins/upload.js'
import '@/assets/font/text.css'
// import VConsole from "vconsole";

// eslint-disable-next-line no-new
// new VConsole();

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
